// import React, {memo} from "react";
import { useState, useEffect, useRef } from "react";
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';

import authAxios from "../../components/authAxios";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";
import { TextField, Autocomplete } from "@mui/material";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import {Button} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ClearIcon from '@mui/icons-material/Clear';

import BorderColorSharpIcon from '@mui/icons-material/BorderColor';

import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


import moment from 'moment';
import UserAlerts from "../../components/UserAlerts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import { Modal, message } from 'antd';
import { faCheckCircle, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons/faCheckDouble";


// const LeaseEdit = ({lease}) => {

const LeaseEdit = () => {
    const location = useLocation();
    const lease = location.state?.lease;

    // useCountRenders()
    const params = useParams()
    // const [isUpdated, setIsUpdated] = useState(false)
    const queryClient = useQueryClient();

    const [error, setError] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);

    // const {fetchData, data, response}  = useAxios();
    // const { data:customerData, fetchData:fetchCustomerData}  = useAxios();   


    // const [dt, setDt] = useState(new Date(lease.end_date));
    const [dt, setDt] = useState(moment(lease.end_date).format("DD-MM-YYYY"));
    const [dt1, setDt1] = useState(null);

    const [endDate, setEndDate] = useState(moment(lease.end_date).startOf('day'));
    const originalDate = moment(lease.end_date).startOf('day');

    // const [txnLines, setTxnLines] = useState([]);
    // const [txnLines, setTxnLines] = useState(lease.subscription_products);

    const [txnLines, setTxnLines] = useState(() => {
        if (lease && lease.subscription_products) {
            return lease.subscription_products.map(product => ({
                sku: product.sku_reference,
                name: product.product_name,
                quantity: product.quantity,
                price: product.price,
                subTotal: product.total,
                total: product.total
            }));
        }
        return [];
    });

    const [item, setItem] = useState();
    const [quantity, setQuantity] = useState();
    // const [amt, setAmt] = useState(0);
    const [subTotal, setSubTotal] = useState();
    const [sku, setSku] = useState();
    const [skuReference, setSkuReference] = useState();
    const [productName, setProductName] = useState();
    const [product, setProduct] = useState({
        sku:"",
        skuReference:"",
        name:"",
        quantity:1, 
        price:0,
        subTotal:0,
        total:0
    });


    // const total = useRef(0)
    const amt = useRef(0)

    const [quantityError, setQuantityError]=useState(false)
    const [totalError, setTotalError]=useState(false)

    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');

    const navigate = useNavigate();

    // const handleChange = (event) => {
    //     setName(event.target.value);
    //   };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [products, setProducts] = useState([]);
    const { data: items } = useQuery({
        queryKey: ['items'],
        queryFn: async () => {
            const response = await authAxios.get("/inventory/items");
            setProducts(response.data)
            return response.data;
        }
    });


    const [editingIndex, setEditingIndex] = useState(null);
    const [originalTotal, setOriginalTotal] = useState(null); // Store the original total


    const mutation = useMutation({
        mutationFn: (leaseData) => {
            return authAxios.put(`rentals/leases/${lease.id}/`, leaseData);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result, variables, context) => {
            message.success(`Successfully updated lease ${result.data.reference}`);
            queryClient.invalidateQueries(['lease', 'leases']);
            queryClient.refetchQueries(['lease']);
            navigate('/dashboard/leases/');
        },
      })

    
    const handleEditTotal = (index, newTotal) => {
        // console.log("IDX",index)
        // console.log("NEW TOTAL",newTotal)
        const updatedTxnLines = [...txnLines];
        updatedTxnLines[index].price = newTotal;
        updatedTxnLines[index].subTotal = newTotal;
        updatedTxnLines[index].total = newTotal;

        // console.log("UPDATED TXN LINES",updatedTxnLines)
        setTxnLines(updatedTxnLines);
    };
    const handleEditClick = (index, productTotal) => {
        if (editingIndex === index) {
            // If already editing, cancel the edit
            setEditingIndex(null);
        } else {
            // Set the editing index and store the original total
            setOriginalTotal(productTotal);
            setEditingIndex(index);
        }
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
    };


    const handleNewProduct = (e) => {
        // e.preventDefault();
        // validDataRef.current=true

        const newProduct = {
            sku:product.sku,
            skuReference:product.skuReference,
            name:product.name,
            quantity:1, 
            price:amt.current,
            subTotal:amt.current,
            total:amt.current
        };

        setTxnLines([...txnLines, newProduct]);
        setIsModalOpen(false); // Close the modal after adding the expense

        // addTxnLine(newProduct);
     
    }

    

    const handleSubmit = (e) => {
        e.preventDefault();

        // if (name === '') {
        //     setNameError(true)
        // }

        // if (name ) {
            // const telephone = phone.replace(/[()" "]/g,"")
            // const end_date = moment(dt).format("YYYY-MM-DD")
            // const end_date = moment(dt1).format("YYYY-MM-DD")

            // const customer = { client, contact, name, address, country, email, telephone, id_type, id_number, gender, birth_date, user };

            // const customer = { client, name, address, city, email, telephone, website, user };
            // const leaseData = { end_date, txnLines }

            const leaseData = { txnLines, user };
            if (!endDate.isSame(originalDate, 'day')) {
                leaseData.end_date = endDate.format("YYYY-MM-DD");
            }
            console.log("LEASE DATA",leaseData)
            mutation.mutate(leaseData)
        // } 
    }

    return(
        <div className="dashboard-main">

        <section className="dashboard-content">

        <section className="dashboard-form dashboard-form-compact">

            <div className="dashboard-content-header">
                <h6 className="dashboard-content-pretitle">
                    Rentals
                </h6>

                <h1 className="dashboard-content-title">
                    Edit Lease
                </h1>
            </div>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        label="Customer" 
                        variant="outlined" 
                        fullWidth
                        required
                        defaultValue = {lease.customer_name}
                        InputProps={{ readOnly: true }}
                    />

                    <TextField className="custom-input"
                        label="Property" 
                        variant="outlined" 
                        fullWidth
                        required
                        defaultValue = {lease.property}
                        InputProps={{ readOnly: true }}
                    />

                </div>
                <div className="grouped-fields-light">
                    <div className="two-columns">
                        <TextField className="custom-input"
                            label="Start Date" 
                            variant="outlined" 
                            fullWidth
                            required
                            defaultValue={moment(lease.start_date).format("DD-MM-YYYY")}
                            InputProps={{ readOnly: true }}
                        />

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newDate) => {
                                    setEndDate(moment(newDate).startOf('day'));
                                }}
                                inputFormat="DD-MM-YYYY"
                                renderInput={(params) => 
                                    <TextField 
                                        fullWidth 
                                        required 
                                        className="custom-input" 
                                        {...params} 
                                    />
                                }
                            />
                        </LocalizationProvider>

                    </div>
                </div>

                <div className="product-details-container">
                    <table className="styled-table" id="lease-edit-table">
                        <thead>
                            <tr >
                                <th>Reference</th>
                                <th>Product</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        {/* <tbody key={index}> */}
                        <tbody>


                        {txnLines && txnLines.map((product, index) => (
                            // <tbody key={index}>
                                // <tr>
                                <tr key={index}>

                                    <td>{product.sku}</td>
                                    <td>{product.name}</td>                              
                                    <td>
                                        {editingIndex === index ? (
                                            <NumberFormat
                                                value={product.total}
                                                onValueChange={(values) => {
                                                    handleEditTotal(index, values.floatValue);
                                                }}
                                                thousandSeparator={true}
                                                // prefix={'$'}
                                                className="custom-input"
                                                customInput={TextField}
                                                variant="standard"
                                                // variant="outlined"
                                                inputProps={{ style: { textAlign: 'right' } }} // Align input text to the right
                                            />
                                        ) 
                                        : 
                                        (
                                            Number(product.total).toLocaleString()
                                        )
                                    }
                                    </td>
                                    <td>
                                        <IconButton 
                                            size="small"
                                            onClick={() => handleEditClick(index, product.total)}
                                          
                                        >
                                            {editingIndex === index ? <DoneAllIcon className="edit-icon" /> : <FontAwesomeIcon icon={faPenToSquare} />}
                                        </IconButton>
                                        {editingIndex === index && (
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    const updatedTxnLines = [...txnLines];
                                                    updatedTxnLines[index].total = originalTotal; 
                                                    setTxnLines(updatedTxnLines);
                                                    handleCancelEdit(); // Exit edit mode
                                                }}
                                            >
                                                <ClearIcon className="edit-icon" /> 
                                            </IconButton>
                                        )}
                                      
                                    </td>
                                </tr>
                                ))}

                            </tbody>
                    </table>
                </div>

                <div className="AddRow">
                    <Button
                        id="AddRowButton"
                        onClick={() => {
                            setIsModalOpen(true)}
                        }
                        variant="outlined" 
                        size="small"
                        startIcon={<AddCircleOutlineIcon />}
                    >
                        Product
                    </Button>
                </div>

                {isModalOpen && 
                <Modal className="dashboard-modal" title="Add Product" visible={isModalOpen} open={isModalOpen} onOk={handleNewProduct} onCancel={handleCancel}>
                    <div className="dashboard-form">
                        <form onSubmit={handleSubmit}>
                            <Autocomplete
                                onChange={(event, newValue) => {
                                    setProductName(newValue.name)
                                    setSkuReference(newValue.sku_reference)
                                    amt.current=newValue.price_data.price
                                    setProduct({
                                        sku:newValue.sku,
                                        // skuReference:newValue.sku_reference,
                                        name:newValue.name,
                                        price:newValue.price_data.price,
                                        subTotal:newValue.price_data.price,
                                        total:newValue.price_data.price
                                    })
                                }}
                                options={products}
                                getOptionLabel={(option) => option.name}
                                // getOptionLabel={(option) => tag==="LEASE"? option.product_name: option.name}

                                renderInput={(params) => 
                                    <TextField 
                                        className='custom-input'
                                        label="Product" 
                                        {...params} 
                                    />
                                }
                            />

                            <NumberFormat 
                                value={amt.current>0?amt.current:""}
                                // value={amt>0?amt:""}

                                onChange={(e) => {
                                    amt.current=parseFloat(e.target.value.replace(/,/g, ''))
                                }} 
                                className="custom-input"
                                fullWidth
                                label='Price'
                                variant="outlined" 
                                customInput={TextField}
                                thousandSeparator={true} 
                                required
                            />
                        </form>
                    </div>
                </Modal>
                }


                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>
            </form>
            </section>
        </section>
        </div>
    )

}

export default LeaseEdit;