import { useEffect, useState, useContext, useRef } from "react";

import authAxios from "../../components/authAxios";

import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';


import { Autocomplete, TextField, CircularProgress } from "@mui/material";

import { message } from "antd";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import {makeStyles} from "@mui/material";
// import { makeStyles } from '@mui/styles';

//ANT DESIGN
import { TreeSelect, Radio, Spin } from "antd";

import NumberFormat from "react-number-format";

import moment from 'moment';
import UserAlerts from "../../components/UserAlerts";
// import Layout from "../../components/Layout";
import {UserContext} from "../../App"
import JournalVoucher from "./JournalVoucher";
import ProductDetails from "../products/ProductDetails";

import Loading from "../../components/Loading";

import '../../styles/AutocompleteStyles.css'
import '../../styles/TreeSelectStyles.css'


// const TransactionNew = ({isDrawerOpen, handleDrawerClose, module}) => {
// const TransactionNew = ({isDrawerOpen, handleDrawerClose}) => {

const TransactionNew = () => {

    const loc = useLocation()

    const module = loc.state.module
    // console.log("STATE",loc.state)


    const queryClient = useQueryClient();

    const errorRef = useRef(null);

    const navigate = useNavigate();
    const page_size = useContext(UserContext).page_size;
    const [error, setError] = useState('');

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: transactionCategories } = useQuery({
        queryKey: ['transactionCategories'],
        queryFn: async () => {
          const response = await authAxios.get(`/accounting/transaction-categories?page_size=${page_size}`);
          return response.data.results

        },
    });

    const { data: transactionTypes } = useQuery({
        queryKey: ['transactionTypes'],
        queryFn: async () => {
            const response = await authAxios.get("/accounting/transaction-types");
            // if (module==="OMS"){
            //     return response.data.filter(type=>(type.code==="010"||type.code==="020"))
            // }
            // else{
            //     // return response.data
            //     return response.data.filter(type=>type.code!=="050")
            // }
            return response.data.filter(type=>
                type.code!=="050" &&
                type.code!=="200" &&
                type.code!=="210" &&
                type.code!=="220")
        },
    });

    const { data: paymentModes } = useQuery({
        queryKey: ['paymentModes'],
        queryFn: async () => {
          const response = await authAxios.get("/accounting/payment-modes");
          return response.data
        },
    });

    const { data: locations } = useQuery({
        queryKey: ['locations'],
        queryFn: async () => {
          const response = await authAxios.get(`/inventory/locations?page_size=${page_size}`);
        //   return response.data
          return response.data.results

        },
    });

    const { data: suppliers } = useQuery({
        queryKey: ['suppliers'],
        queryFn: async () => {
          const response = await authAxios.get(`/purchase/suppliers?page_size=${page_size}`);
          return response.data.results

        },
    });

    const { data: owners } = useQuery({
        queryKey: ['owners'],
        queryFn: async () => {
          const response = await authAxios.get("/rentals/owners");
          return response.data
        },
    });

    const { data: accounttypes } = useQuery({
        queryKey: ['accounttypes'],
        queryFn: async () => {
          const response = await authAxios.get("/accounting/account-types");
          return response.data
        },
    });

    const { data: accts } = useQuery({
        queryKey: ['accounts-tree'],
        queryFn: async () => {
          const response = await authAxios.get("/accounting/accounts-tree");
          return response.data
        },
    });

    // const { data: customers } = useQuery({
    //     queryKey: ['customers'],
    //     queryFn: async () => {
    //     //   const response = await authAxios.get(`/crm/customers?page_size=${page_size}`);
    //       const response = await authAxios.get(`/rentals/tenants?page_size=${page_size}`);
    //       return response.data.results
    //     },
    // });

    const { data: leases, isLoading } = useQuery({
        queryKey: ['leases'],
        queryFn: async () => {
            // const response = await authAxios.get(`/rentals/leases/?page_size=${page_size}`);
            // return response.data.results

            const response = await authAxios.get("/rentals/leases");
            return response.data
        },
    });

    const [accounts, setAccounts] = useState([]);
    const [accounts2, setAccounts2] = useState([]);
    

    // const client = localStorage.getItem('clientID');
    // const { data: clientprofile } = useAxios({

    const [isModalOpen, setIsModalOpen] = useState(false);

    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const [reference, setReference] = useState('');
    const [docRef, setDocRef] = useState('');
    const [dt, setDt] = useState(new Date());
    const [account1, setAccount1] = useState('');
    const [account2, setAccount2] = useState('');
    const [accountTree, setAccountTree] = useState('');

    const [transactionCategory, setTransactionCategory] = useState([]);
    const [balanceTransactionType, setBalanceTransactionType] = useState('');
    const [subledgerType, setSubledgerType] = useState('');


    const [type, setType] = useState('');
    const [amt, setAmt] = useState('');
    const [entryType, setEntryType] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [description, setDescription] = useState('');
    const [memo, setMemo] = useState('');
    const [customer, setCustomer] = useState('');
    const [location, setLocation] = useState('');
    const [supplier, setSupplier] = useState('');
    const [owner, setOwner] = useState('');

    const [lease, setLease] = useState('');

    const [isInvoice, setIsInvoice] = useState(false);
    const [isJournalVoucher, setIsJournalVoucher] = useState(false);
    const [isGrn, setIsGrn] = useState(false);

    const [referenceError, setReferenceError] = useState(false);
    const [amtError, setAmtError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [customerError, setCustomerError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)
    const [balancingError, setBalancingError] = useState(false);

    const [showPaymentMode, setShowPaymentMode] = useState(false);
    const [showDocRef, setShowDocRef] = useState(false);
    const [showCustomers, setShowCustomers] = useState(false);
    const [showOwners, setShowOwners] = useState(false);
    const [showSuppliers, setShowSuppliers] = useState(false);

    const [showLocation, setShowLocation] = useState(false);
    const [showAccount1, setShowAccount1] = useState(false);
    const [showAccount2, setShowAccount2] = useState(false);
    const [showAmount, setShowAmount] = useState(false);
    const [showBlock2, setShowBlock2] = useState(false);
    const [showProducts, setShowProducts] = useState(false);
    const [showClasses, setShowClasses] = useState(false);


    const [accountLabel, setAccountLabel] = useState("Account")
    const [accountStatus, setAccountStatus] = useState("")

    const [account2Label, setAccount2Label] = useState("")
    const [account2Status, setAccount2Status] = useState("")

    const [refLabel, setRefLabel] = useState("Reference")
    const [docRefLabel, setDocRefLabel] = useState("Reference")

    // const [txnLines, setTxnLines] = useState([{}]);
    const [txnLines, setTxnLines] = useState([]);

    const [title, setTitle] = useState ("")
    const [subTitle, setSubTitle] = useState ("")
    const [tag, setTag] = useState ("")

    const [txnType, setTxnType] = useState ("")


    // const txn = useRef(null);
    // const txn = {
    //     code: "",
    //   };

    const [value, setValue] = useState();

    // const handleTransType = (e) => {
    const handleTransType = (newValue) => {
        // console.log("NEWVALUE",newValue)
        setType(newValue.id)
        setTxnType(newValue.code)

        setTypeError(false)
        setReference('');
        setShowCustomers(false)
        setShowLocation(false)
        setShowSuppliers(false)
        setShowAccount1(false)
        setShowAccount2(false)
        setShowAmount(false)
        setShowBlock2(false)
        setShowDocRef(false)
        setIsGrn(false)

        if (clientProfile.transaction_class_tracking){
            setShowClasses(true)
        }

        if (newValue.code==="010" || newValue.code==="020"){
            setShowCustomers(true)
        }

        // BALANCE B/F TRANSACTION
        if (newValue.code==="000"){
            setIsInvoice(false)
            setShowAmount(false)
            setShowPaymentMode(false)
            setIsJournalVoucher(false)
            setShowProducts(false)
            setShowAmount(true)
            setShowBlock2(true)

            try {
                const nextSequence = clientProfile.sequence.find(sequence => sequence.code === "000");
                const balanceRef = nextSequence.prefix + String(nextSequence.next_value).padStart(8, '0')
                setReference(balanceRef)
            }

            catch (error) {
                console.error("ERROR",error);
                // Handle the error (e.g., display error message)
                setError("Sequence for this transaction type has not been setup")
              }


        }

        // INVOICE TRANSACTION
        if (newValue.code==="010"){

            // const invoiceType = transactionTypes.filter(ttype => ttype.code === "010")  
            // console.log("INV TYPE",invoiceType)

            setIsInvoice(true)
            setShowAmount(false)
            setShowPaymentMode(false)
            setIsJournalVoucher(false)
            setTitle("Successful! Your invoice has been posted")
            setSubTitle(`Invoice number:${reference} can now be shared with the customer via print, eamil or WhatsApp.`)
            // setType(invoiceType.id)
            setReference(clientProfile.invoice_no)
            setShowProducts(true)

            if (clientProfile.inventory_tracking){
                setShowLocation(true)
            }
            else{
                setShowLocation(false)
            }

            // const invoiceNo = String(clientProfile.invoice_no).padStart(8, '0')
            const invoiceNo = clientProfile.invoice_prefix + String(clientProfile.invoice_no).padStart(8, '0')

            setReference(invoiceNo)

            //Set transaction type for invoices to to Debit
            setEntryType("D")
            setTag("INV")

            //Hide and default to Control Account 
            if (clientProfile.debtors_control){
                setAccount1(clientProfile.debtors_control);
            }
            else{
                setShowAccount1(true)
                // Filter receivable accounts only
                const accts1 = accts.filter(acct => acct.type === "30200");
                // setAccounts(accts1)
                setAccounts(transformDataToTree(accts1))
            }

        }

        // RECEIPT TRANSACTION
        if (newValue.code==="020"){
            setIsInvoice(false)
            setIsJournalVoucher(false)
            setShowDocRef(true)
            setShowLocation(false)
            setShowAmount(true)
            setShowBlock2(true)
            setShowPaymentMode(true)


            //ASSIGN THE NEXT RECEIPT NUMBER
            // const receiptNo = String(clientProfile.receipt_no).padStart(8, '0')
            const receiptNo = clientProfile.receipt_prefix + String(clientProfile.receipt_no).padStart(8, '0')

            setReference(receiptNo)
            setRefLabel("Receipt No")

            //SET TRANSACTION ENTRY TYPE FOR RECEIPTS TO BANK/CASH ACCOUNTS
            setEntryType("D")

            // FILTER BANK AND CASH ACCOUNTS ONLY 
            const acctype = accounttypes.find(accounttype => accounttype.code === "30100");

            // const accts1 = Array.from(accts.results).filter(acct => acct.type === acctype.id);
            const accts1 = accts.filter(acct => acct.type === acctype.id);

            // setAccounts(accts1)
            setAccounts(transformDataToTree(accts1))
            setShowAccount1(true)


            //Hide and default to Control Account 
            if (clientProfile.debtors_control){
                setAccount2(clientProfile.debtors_control);
            }
            else{
                setShowAccount2(true)
                const accts2 = accts.filter(acct => acct.type === "30200");
                // setAccounts2(accts2)
                setAccounts2(transformDataToTree(accts2))

            }

        }

        // CREDIT NOTE TRANSACTION
        if (newValue.code==="030"){
            // const invoiceType = transactionTypes.filter(ttype => ttype.code === "030")   

            // setIsInvoice(true)
            setShowCustomers(true)
            setShowAmount(false)
            setShowPaymentMode(false)
            setIsJournalVoucher(false)

            // setTitle("Successful! Your invoice has been posted")
            // setSubTitle(`Invoice number:${reference} can now be shared with the customer via print, eamil or WhatsApp.`)
            // setType(invoiceType.id)
            // setReference(clientProfile.credit_note_no)
            setShowProducts(true)

            if (clientProfile.inventory_tracking){
                setShowLocation(true)
            }
            else{
                setShowLocation(false)
            }


            // const creditNoteNo = String(clientProfile.credit_note_no).padStart(8, '0')
            const creditNoteNo = clientProfile.credit_note_prefix + String(clientProfile.credit_note_no).padStart(8, '0')

            setReference(creditNoteNo)

            //Set transaction type for invoices to to Debit
            setEntryType("C")
            setTag("CRN")

            //Hide and default to Control Account 
            if (clientProfile.debtors_control){
                setAccount1(clientProfile.debtors_control);
            }
            else{
                setShowAccount1(true)
                // Filter receivable accounts only
                const accts1 = accts.filter(acct => acct.type === "30200");
                setAccounts(transformDataToTree(accts1))
            }
        }



        // DEBIT NOTE
        if (newValue.code==="040"){
            setShowCustomers(true)
            setIsInvoice(false)
            setShowSuppliers(false)
            setShowAmount(true)
            setShowBlock2(true)
            setShowAccount2(true)
            setShowPaymentMode(false)
            // setShowClasses(true)


            try {
                const nextSequence = clientProfile.sequence.find(sequence => sequence.code === "040");
                const debitNoteRef = nextSequence.prefix + String(nextSequence.next_value).padStart(8, '0')
                setReference(debitNoteRef)
            }

            catch (error) {
                console.error("ERROR",error);
                // Handle the error (e.g., display error message)
                setError("Sequence for this transaction type has not been setup")
            }

            
            //NOTE: This section has been moved to handleSubledger
            //Default to Control Account 
            // if (clientProfile.debtors_control){
            //     setAccount1(clientProfile.debtors_control);
            // }
            // else{
            //     setShowAccount1(true)
            //     const accts1 = accts.filter(acct => acct.type === "30200");
            //     setAccounts(transformDataToTree(accts1))
            // }

            // FILTER BANK/CASH AND CREDIT CARD ACCOUNTS ONLY 
            const acctype = accounttypes.filter(accounttype => (accounttype.code === "30100"||accounttype.code === "40150"));
            const acctypeIDs = acctype.map(acct => acct.id);
            const accts2 = accts.filter(acct => acctypeIDs.includes(acct.type));
            setAccounts2(transformDataToTree(accts2))
            setAccount2Label("Bank/Cash Account")
            setEntryType("D")
        }


        // POST BILLS
        if (newValue.code==="100"){
            setShowCustomers(false)
            setIsInvoice(false)
            setShowPaymentMode(false)
            setShowLocation(false)
            setShowSuppliers(true)
            setShowAmount(true)
            setShowBlock2(true)
            // setShowClasses(true)



            //Hide and default to Control Account 
            if (clientProfile.ap_account){
                setAccount1(clientProfile.ap_account);
            }
            else{
                setShowAccount1(true)
                const accts1 = accts.filter(acct => acct.type === "40100");
                // setAccounts(accts1)
                setAccounts(transformDataToTree(accts1))
            }

            // CONTRA ACCOUNT - FILTER EXPENSE ACCOUNTS ONLY 
            // const acctype2 = accounttypes.find(accounttype => accounttype.code === "20100");
            const acctype2 = accounttypes.filter(accounttype => accounttype.code === "20100" || accounttype.code === "20200" || accounttype.code === "20300");
            
             // Extract the ID from each object in the acctype2 array
            const acctype2IDs = acctype2.map(acct2 => acct2.id);

            // Use includes function to check if the account type in the Accounts array 
            //  const accts2 = accts.filter(acct2 => acct2.type === acctype2.id);
            const accts2 = accts.filter(acct2 => acctype2IDs.includes(acct2.type));

            // setAccounts2(accts2)
            setAccounts2(transformDataToTree(accts2))
            setShowAccount2(true)

            setAccountLabel("A/P Account")
            setAccount2Label("Expense Account")
            setEntryType("C")
        }

        // POST PAYMENT
        if (newValue.code==="110"){
            setShowCustomers(false)
            setIsInvoice(false)
            setShowSuppliers(true)
            setShowAmount(true)
            setShowBlock2(true)
            setShowAccount2(true)
            setShowPaymentMode(false)
            // setShowClasses(true)


            // HIDE CONTRA ACCOUNT IF DEFAULT ACCOUNTS PAYABLE EXISIS 
            // ACCOUNT 1 SHOULD BE AP. DO NOT SWITCH!
            if (clientProfile.ap_account){
                setAccount1(clientProfile.ap_account);
                setShowAccount1(false)
            }
            else{
                setShowAccount1(true)
                const accts1 = accts.filter(acct => acct.type === "40100");
                // setAccounts(accts1)
                setAccounts(transformDataToTree(accts1))
            }

            // FILTER BANK/CASH AND CREDIT CARD ACCOUNTS ONLY 
            // const acctype = accounttypes.find(accounttype => accounttype.code === "30100");
            // const accts2 = accts.filter(acct => acct.type === acctype.id);

            const acctype = accounttypes.filter(accounttype => (accounttype.code === "30100"||accounttype.code === "40150"));
            const acctypeIDs = acctype.map(acct => acct.id);
            const accts2 = accts.filter(acct => acctypeIDs.includes(acct.type));
            setAccounts2(transformDataToTree(accts2))

            setAccount2Label("Bank/Cash Account")
            setEntryType("D")
        }

        // POST GRN
        if (newValue.code==="200"){
            setIsInvoice(false)
            setIsGrn(true)
            setIsJournalVoucher(false)
            setShowCustomers(false)
            setShowLocation(true)
            setShowSuppliers(true)
            setShowAmount(false)
            setShowPaymentMode(false)
            setEntryType("D")
            setTag("GRN")
        }


        // POST JOURNAL VOUCHERS
        if (newValue.code==="900"){
            setIsInvoice(false)
            setIsGrn(false)
            setShowCustomers(false)
            setIsJournalVoucher(true)
            setShowAmount(false)
            setShowPaymentMode(false)
            // setShowClasses(true)


            setReference(clientProfile.journal_no)
            const jvNo = clientProfile.journal_prefix + String(clientProfile.journal_no).padStart(7, '0')
            // const jvNo = String(clientProfile.journal_no).padStart(8, '0')
            setReference(jvNo)
        }

        if (newValue.code==="901"){
            setShowCustomers(true)
            setIsInvoice(false)
            setShowDocRef(true)
            setShowAmount(true)
            setShowBlock2(true)
            setShowAccount2(false)
            setShowPaymentMode(false)
            setShowOwners(false)
            setShowSuppliers(false)
            // setShowClasses(true)

            //ASSIGN THE NEXT RECEIPT NUMBER
            const receiptNo = clientProfile.receipt_prefix + String(clientProfile.receipt_no).padStart(8, '0')
            setReference(receiptNo)
            setRefLabel("Receipt No")


            // // HIDE CONTRA ACCOUNT IF DEFAULT ACCOUNTS PAYABLE EXISIS 
            // // ACCOUNT 1 SHOULD BE AP. DO NOT SWITCH!
            // if (clientProfile.ap_account){
            //     setAccount1(clientProfile.ap_account);
            //     setShowAccount1(false)
            // }
            // else{
            //     setShowAccount1(true)
            //     const accts1 = accts.filter(acct => acct.type === "40100");
            //     // setAccounts(accts1)
            //     setAccounts(transformDataToTree(accts1))
            // }



            setAccount1(clientProfile.ap_account);
            setAccount2(clientProfile.debtors_control);


            // FILTER BANK/CASH AND CREDIT CARD ACCOUNTS ONLY 
            // const acctype = accounttypes.find(accounttype => accounttype.code === "30100");
            // const accts2 = accts.filter(acct => acct.type === acctype.id);

            // const acctype = accounttypes.filter(accounttype => (accounttype.code === "30100"||accounttype.code === "40150"));
            // const acctypeIDs = acctype.map(acct => acct.id);
            // const accts2 = accts.filter(acct => acctypeIDs.includes(acct.type));
            // setAccounts2(transformDataToTree(accts2))
            // setAccount2Label("Bank/Cash Account")

            setEntryType("D")
        }

        // POST EXPENSES
        if (newValue.code==="910"){
            setIsInvoice(false)
            setIsGrn(false)
            setIsJournalVoucher(false)
            setShowPaymentMode(false)
            setShowCustomers(false)
            setShowAccount1(true)
            setShowAccount2(true)
            setShowAmount(true)
            setShowBlock2(true)
            // setShowProperties(true)
            // setShowClasses(true)

            setAccountLabel("Bank/Cash Account")
            setAccount2Label("Expense Account")
            setEntryType("C")

            // FILTER BANK/CASH AND CREDIT CARD ACCOUNTS ONLY 
            // const acctype = accounttypes.find(accounttype => accounttype.code === "30100");
            // const accts1 = accts.filter(acct => acct.type === acctype.id);
            // setAccounts(transformDataToTree(accts1))

            const acctype = accounttypes.filter(accounttype => (accounttype.code === "30100"||accounttype.code === "40150"));
            const acctypeIDs = acctype.map(acct => acct.id);
            const accts1 = accts.filter(acct => acctypeIDs.includes(acct.type));
            setAccounts(transformDataToTree(accts1))


            // CONTRA ACCOUNT - FILTER EXPENSE ACCOUNTS ONLY 
            // const acctype2 = accounttypes.find(accounttype => accounttype.code === "20100");
            const acctype2 = accounttypes.filter(accounttype => accounttype.code === "20100" || accounttype.code === "20200" || accounttype.code === "20300");

            // Extract the ID from each object in the acctype2 array
            const acctype2IDs = acctype2.map(acct2 => acct2.id);

            // Use includes function to check if the account type in the Accounts array 
            const accts2 = accts.filter(acct2 => acctype2IDs.includes(acct2.type));
            setAccounts2(transformDataToTree(accts2))
        }
    }

    const handleBalanceTransaction = (e) =>{
        // const mode = paymentModes.find(mode => mode.code === e.target.value)
        setBalanceTransactionType(e.target.value)

        if (e.target.value==="10"){
            setShowCustomers(true)
            setAccount1(clientProfile.debtors_control);
        }
    }

    const handleSubledger = (e) =>{
        setBalanceTransactionType(e.target.value)
        setSubledgerType(e.target.value)

        switch (e.target.value){
            case "10":
                setShowCustomers(true)
                setShowOwners(false)
                setShowSuppliers(false)
                setAccount1(clientProfile.debtors_control);
                break;
            case "20":
                setShowCustomers(false)
                setShowOwners(true)
                setShowSuppliers(false)
                setAccount1(clientProfile.ap_account);
                break;
            case "30":
                setShowCustomers(false)
                setShowOwners(false)
                setShowSuppliers(true)
                break;
        }
    }

    const handlePaymentMode = (e) =>{
        const mode = paymentModes.find(mode => mode.code === e.target.value)
        setPaymentMode(mode.id)
    }

    // Function to recursively transform data to the expected tree structure
    const transformDataToTree = (data) => {
        return data.map((item) => ({
            value: item.id.toString(), // Convert to string if needed
            title: item.name,
            // children: item.sub_accounts? 
            //     item.sub_accounts.map((child) => ({
            //         value: child.id.toString(), // Convert to string if needed
            //         title: child.name,
            //         children: [], // No need to recursively call here
            //     }))
            // : [], // No children for this item
            children: item.sub_accounts ? transformDataToTree(item.sub_accounts) : [],
        }));
    };

    const filterTreeNode = (input, treeNode) => {
        const nodeLabel = treeNode.title.toLowerCase(); // Access title directly
        return nodeLabel.includes(input.toLowerCase());
    };

    const handleSelectedAccount = (e) => {
        setAccountStatus("")
        setAccount1(e)
        console.log("E", e)
        const selectedAccount = accounts.find((account) => account.value === e);
        // console.log("SELECTED ACCOUNT", selectedAccount)

        if (selectedAccount && selectedAccount.children.length) {
            setAccountStatus("error")
            // console.log("PARENT")
            return;
          }
    }

    const handleSelectedAccount2 = (e) => {
        setAccount2Status("")
        setAccount2(e)
        const selectedAccount2 = accounts2.find((account) => account.value === e);
        // console.log("SELECTED ACCOUNT", selectedAccount)

        if (selectedAccount2 && selectedAccount2.children.length) {
            setAccount2Status("error")
            // console.log("PARENT")
            return;
          }
    }

    const addJv = (newJv) => {
        // console.log("WE ARE HERE",newExpense)
        setTxnLines([...txnLines, newJv]);
        // setExpenses([...expenses, newExpense]);
        setIsModalOpen(false); // Close the modal after adding the expense
    };

    const addTxnLine = (newTxnLine) => {
        // console.log("WE ARE HERE",newExpense)
        setTxnLines([...txnLines, newTxnLine]);
        // setExpenses([...expenses, newExpense]);
        setIsModalOpen(false); // Close the modal after adding the expense
    };


    const mutation = useMutation({
        mutationFn: (transaction) => {
            return authAxios.post("/accounting/transactions/", transaction);
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            message.success(`Successfully created transaction ${result.data.reference}`);

            queryClient.invalidateQueries(['transactions']);
            if (isInvoice){
                navigate('/dashboard/confirmed/', {state:{title:title, subtitle:subTitle, reference:reference}});
            }
            else {
                navigate('/dashboard/transactions/');
            }
        },
      })


    const handleSubmit = (e) => {
        // console.log("LINES", txnLines)

        e.preventDefault();

        setAmtError(false)

        if (reference === "" ) {
            // setTypeError(true)
            setReferenceError(true)
        }

        if (!isJournalVoucher && !amt > 0 ) {
            setAmtError(true)
        }

        if (type === "" ) {
            setTypeError(true)
        }

        if (!isJournalVoucher && description === "" ) {
            setDescriptionError(true)
        }

        // console.log(reference, amt, type, memo)
        if (reference && !amtError && type && !descriptionError && !balancingError) {

            let entry_type = entryType
            let account = account1
            const contra = account2
            const payment_mode = paymentMode
            const doc_ref = docRef
            const txn_lines = txnLines;

            //Change the format of Price field before saving
            let amount = 0
            // const amount = parseFloat(amt.replace(/,/g, ''));
            // if (isInvoice){

            if (txnType==="010"||txnType==="030"){
                // amount = amt
                amount = txnLines.reduce((acc, ln) => acc + parseFloat(ln.total), 0);
            }
            else {
                if (!isJournalVoucher && !isGrn){
                amount = parseFloat(amt.replace(/,/g, ''));
                }
            }
            // if (entry_type = "C") {
            //     amount = amount * -1
            // }

            //Change the format of transaction date before saving
            const date = moment(dt).format("YYYY-MM-DD")

            const order = "" //False variable. Do not delete.

            const transaction_category = transactionCategory
            const balance_transaction_type = balanceTransactionType
            const subledger_type = subledgerType


            const app = "RENTALS"

            const transaction = { app, client, transaction_category, customer, lease, location, supplier, owner, order, reference, doc_ref, date, type, payment_mode, balance_transaction_type, subledger_type, account, contra, amount, description, memo, entry_type, txn_lines, user };
            console.log("TRANSACTION",transaction)
            mutation.mutate(transaction)
        } 
    }

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    // if (isLoading) return <Spin size="large" />;
    if (isLoading) return <Loading loading={true} />;



    return (
            <div className="dashboard-main">
               
                <section className="dashboard-content">

                <section className="dashboard-form dashboard-form-compact">
               
                {/* <section className="dashboard-form"> */}

                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>

                    

                    <div className="dashboard-content-header">
                        <h6 className="dashboard-content-pretitle">
                            Accounting
                        </h6>

                        <h1 className="dashboard-content-title">
                            New Transaction
                        </h1>
                    </div>


                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <div className="grouped-fields-light">

                        <Autocomplete
                            onChange={(event, newValue) => {
                                // console.log("VALUE", newValue.id)
                                handleTransType(newValue)
                                }}
                            options={transactionTypes}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => 
                                <TextField 
                                    className="custom-input" 
                                    fullWidth 
                                    error={typeError} 
                                    required
                                    label="Type"  
                                    {...params} 
                                /> 
                            }                          
                        />

                        {(txnType==="000" || txnType==="040") &&
                        <div>
                            {/* <Radio.Group onChange={handleBalanceTransaction} className="custom-input"> */}
                            <Radio.Group onChange={handleSubledger} className="custom-input">
                                <Radio value="10">Tenant</Radio>
                                {/* <Radio disabled value="20">Owner</Radio> */}
                                <Radio disabled={txnType === "000"} value="20">Owner</Radio>
                                <Radio disabled value="30">Supplier</Radio>
                                <Radio disabled value="40">Employee</Radio>
                            </Radio.Group>
                        </div>
                        }

                        {txnType==="110" &&
                        <div>
                            {/* <Radio.Group onChange={handleBalanceTransaction} className="custom-input"> */}
                            <Radio.Group onChange={handleSubledger} className="custom-input">
                                <Radio disabled value="10">Tenant</Radio>
                                <Radio value="20">Owner</Radio>
                                <Radio value="30">Supplier</Radio>
                                <Radio disabled value="40">Employee</Radio>
                            </Radio.Group>
                        </div>
                        }

                        {showPaymentMode && 
                        <Radio.Group onChange={handlePaymentMode} className="custom-input">
                            <Radio value="10">Cash</Radio>
                            <Radio value="20">Mobile Money</Radio>
                            <Radio value="30">Credit Card</Radio>
                            <Radio value="40">Cheque</Radio>
                        </Radio.Group>
                        }

                        <div className="two-columns">
                            <TextField className="custom-input"
                                onChange={(e) => {
                                    setReferenceError(false)
                                    setReference(e.target.value)}
                                }
                                // label="Reference" 
                                label={refLabel}
                                variant="outlined" 
                                fullWidth
                                required
                                value = {reference}
                                error={referenceError}
                            />

                            {showDocRef &&
                            <TextField className="custom-input"
                                onChange={(e) => {
                                    setDocRef(e.target.value)}
                                }
                                // label="Reference" 
                                label={docRefLabel}
                                variant="outlined" 
                                fullWidth
                                // required
                                value = {docRef}
                                // error={referenceError}
                            />
                            }

                            
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    label="Transaction Date"
                                    value={dt}
                                    onChange={(newDate) => {
                                        setDt(moment(newDate).format("YYYY-MM-DD"));
                                    }}
                                    renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                                />
                            </LocalizationProvider>
                        </div>


                        {showClasses &&
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                setTransactionCategory(newValue);
                                }}
                            options={transactionCategories}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Transaction Class"  /> }
                        />
                         
                        }


                        {showCustomers && 
                        <>

                        <Autocomplete
                            onChange={(event, newValue) => {
                                setLease(newValue.id);
                                setCustomer(newValue.customer);
                                setCustomerError(false)
                                }}
                            options={leases}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Customer"  /> }
                        />
                      
                        </>

                        }

                        {showLocation &&
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setLocation(newValue.id);
                                // console.log("VALUE", newValue.id)
                                }}
                            options={locations}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Location"  /> }
                        />
                        }

                        {showSuppliers &&
                        <Autocomplete
                            onChange={(event, newValue) => {
                                // console.log("VALUE", newValue.id)

                                setSupplier(newValue.id);
                                }}
                            options={suppliers}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Supplier"  /> }
                        />
                        }

                        {showOwners &&
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setOwner(newValue.id);
                                }}
                            options={owners}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Owner"  /> }
                        />
                        }
                        
                        </div>

                        {showBlock2 &&
                        <>
                        <div className="grouped-fields-light">
                            <div className="two-columns">

                                {showAccount1 && 
                                    <TreeSelect
                                        // className="custom-tree-select"
                                        
                                        // value={account1}
                                        status={accountStatus}
                                        style={{
                                            width: '100%',
                                        }}
                                        // value={value}
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                        }}
                                        placeholder={accountLabel}
                                        allowClear
                                        // treeDefaultExpandAll
                                        showSearch
                                        onChange={handleSelectedAccount}
                                        // onChange={(e) => {
                                        //     console.log("SELECTED",e)
                                        //     setAccount1(e)
                                        // }}
                                        // fieldNames={label: {name}, value: id, children: sub_accounts}
                                        treeData={accounts}
                                        filterTreeNode={filterTreeNode} //Enables search filter
                                    />
                                }

                                {showAccount2 && 
                                    <TreeSelect
                                        // className="custom-tree-select"
                                        style={{
                                            width: '100%',
                                        }}
                                        // value={value}
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                        }}
                                        placeholder={account2Label}
                                        status={account2Status}
                                        allowClear
                                        showSearch
                                        onChange={handleSelectedAccount2}
                                        // onChange={(e) => {setAccount2(e)}}
                                        // fieldNames={label: {name}, value: id, children: sub_accounts}
                                        treeData={accounts2}
                                        filterTreeNode={filterTreeNode} //Enables search filter
                                    />
                                }
                            </div>

                            {showAmount &&
                            <>
                            <NumberFormat 
                                onChange={(e) => setAmt(e.target.value)} 
                                className="custom-input"
                                fullWidth
                                label="Amount" 
                                variant="outlined" 
                                customInput={TextField}
                                thousandSeparator={true} 
                                required
                                error={amtError}

                            />
                            {txnType==="000" &&
                            <div>
                                <Radio.Group onChange={(e) => setEntryType(e.target.value)} className="custom-input">
                                    <Radio value="D">Debit</Radio>
                                    <Radio value="C">Credit</Radio>
                                </Radio.Group>
                            </div>
                            }
                            </>

                            }
                        </div>
                        </> 

                        }

                        {/* {isInvoice && */}
                        {showProducts &&
                            <ProductDetails 
                                addTxnLine={addTxnLine}
                                txnLines={txnLines} 
                                setTxnLines={setTxnLines} 
                                tag={tag}
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                clientProfile={clientProfile}
                                location={location}
                                // customer={customer} //Added to support price lists
                                lease={lease} //Added to support price lists
                            />
                        }

                        {isJournalVoucher &&
                            <JournalVoucher 
                                accts={accts}
                                clientProfile={clientProfile}
                                addTxnLine={addTxnLine}
                                txnLines={txnLines} 
                                setTxnLines={setTxnLines} 
                                tag={tag}
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                            />
                            
                        }
                        {isGrn &&
                            <ProductDetails 
                                addTxnLine={addTxnLine}
                                txnLines={txnLines} 
                                setTxnLines={setTxnLines} 
                                tag={tag}
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                clientProfile={clientProfile}
                            />
                        }

                        <div className="grouped-fields-light">

                        {!isJournalVoucher &&
                        <TextField className="custom-input"
                            onChange={(e) => {
                                setDescriptionError(false)
                                setDescription(e.target.value)}
                            }
                            label="Description" 
                            variant="outlined" 
                            fullWidth
                            required
                            value = {description}
                            error={descriptionError}
                            inputProps={{ maxLength: 48 }}
                        />
                        }

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setMemo(e.target.value)
                            }}
                            label="Memo"
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth
                            // error={memoError}
                        />
                        </div>

                        <div className="button-border-top">
                            <button 
                                id="submit" 
                                className="btn btn-primary" 
                                type="submit"
                                disabled={mutation.isLoading} // Disable button while loading   
                            >
                                {mutation.isLoading ? 'Saving...' : 'Save'}
                                {/* Save */}
                            </button>
                        </div>
                        {/* Show the loading icon while the mutation is loading */}
                        {/* {mutation.isLoading && <Loading loading={true} />} */}
                    </form>

                </section>
                </section>
            </div>   
    ) 
}

export default TransactionNew;
