// import React, {memo} from "react";
import { useState, useRef } from "react";
import { useQueryClient, useMutation } from '@tanstack/react-query';
import authAxios from "../../components/authAxios";

import { useNavigate, useParams } from "react-router-dom";

import { TextField, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { message } from "antd";

import NumberFormat from "react-number-format";

import UserAlerts from "../../components/UserAlerts";


// const CustomerView = ({customer, setError, contact, countries}) => {
const CustomerView = ({customer}) => {

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    // const [phoneNumbers, setPhoneNumbers] = useState(customer.phone_numbers || []);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingValue, setEditingValue] = useState('');  // For temporary edits
    const [newNumber, setNewNumber] = useState('');
    const [isAddingNew, setIsAddingNew] = useState(false);

    const contact = customer.contact;

    // Add Phone Number Mutation
    const addPhoneMutation = useMutation({
        mutationFn: (phoneData) => {
            // return authAxios.post(`contacts/contacts/${contact}/phone-numbers/`, {number: formattedNumber});
            return authAxios.post(`contacts/contacts/${contact}/phone-numbers/`, phoneData);

        },
        onError: (error) => {
            if (error.response?.data?.error) {
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.forEach(msg => setError(msg));
                } else {
                    setError(error.response.data.error);
                }
            } else {
                setError(error.message);
            }
        },
        onSuccess: () => {
            // Add console log to verify the customer ID
            message.success(`Successfully added phone number to contact`);
            queryClient.invalidateQueries(['customer']);
            queryClient.refetchQueries(['customer']);
            setIsAddingNew(false);
            setNewNumber('');
        },
    });

    // Update Phone Number Mutation
    const updatePhoneMutation = useMutation({
        mutationFn: ({id, phoneData}) => {
            console.log("phoneData", phoneData);
            // return authAxios.put(`contacts/phone-numbers/${id}/`, data);
            return authAxios.put(`contacts/contacts/${contact}/phone-numbers/${id}/`, phoneData);
        },
        onError: (error) => {
            if (error.response?.data?.error) {
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.forEach(msg => setError(msg));
                } else {
                    setError(error.response.data.error);
                }
            } else {
                setError(error.message);
            }
        },
        onSuccess: () => {
            message.success(`Successfully updated phone number`);
            queryClient.invalidateQueries(['customer']);
            setEditingIndex(null);
        },
    });

    // Delete Phone Number Mutation
    const deletePhoneMutation = useMutation({
        mutationFn: (id) => {
            // return authAxios.delete(`contacts/phone-numbers/${id}/`);
            return authAxios.delete(`contacts/contacts/${contact}/phone-numbers/${id}/`);
        },
        onError: (error) => {
            if (error.response?.data?.error) {
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.forEach(msg => setError(msg));
                } else {
                    setError(error.response.data.error);
                }
            } else {
                setError(error.message);
            }
        },
        onSuccess: () => {
            message.success(`Successfully deleted phone number`);
            queryClient.invalidateQueries(['customer']);
        },
    });

    // Handler functions
    const handleSaveNew = () => {
        if (newNumber) {
            const number = newNumber.replace(/\D/g, '');
            const phoneData = {number};

            addPhoneMutation.mutate(phoneData);
        }
    };

    const handleSaveEdit = (index) => {
        // const phone = phoneNumbers[index];
        console.log("index", index);
        const customerPhone = customer.phone_numbers[index];
        console.log("customerPhone", customerPhone);
        // updatePhoneMutation.mutate({
        //     id: phone.id,
        //     data: {
        //         contact: contact.id,
        //         number: phone.number
        //     }
        // });
        // const number = phoneNumber.replace(/\D/g, '');
        const number = editingValue.replace(/\D/g, '');
        const phoneNumberId = customerPhone.id;

        const phoneData = {number};
        // updatePhoneMutation.mutate(phoneNumberId, phoneData);
        updatePhoneMutation.mutate({
            id: phoneNumberId,
            phoneData: { number }
        });
    };

    const handleDelete = (id) => {
        deletePhoneMutation.mutate(id);
    };

    const handleStartEdit = (index, number) => {
        setEditingIndex(index);
        setEditingValue(number);
    };

    const handleEditChange = (value) => {
        setEditingValue(value);
    };



    return(
        // <section className="dashboard-form">
        <div>

        <div ref={errorRef}>
            <UserAlerts error={error}  />
        </div>
        <section className="dashboard-form" style={{ display: 'flex', gap: '2rem' }}>

            {customer &&
            <>
                <div style={{ flex: '0 0 60%' }}>
                    <form noValidate autoComplete="off" >
                        <div className="grouped-fields-light">
                            <TextField className="custom-input"
                                defaultValue = {customer.name}
                                label="Name" 
                                InputProps={{ readOnly: true }}
                                variant="outlined" 
                                fullWidth
                            />
                            <TextField className="custom-input"
                                defaultValue = {customer.email}
                                label="Email" 
                                InputProps={{ readOnly: true }}
                                variant="outlined" 
                                fullWidth
                            />

                            <NumberFormat 
                                defaultValue = {customer.telephone}
                                className="custom-input"
                                fullWidth
                                label="Mobile" 
                                variant="outlined" 
                                customInput={TextField}
                                format="### (###) ######" mask="_"
                                InputProps={{ readOnly: true }}
                            />

                            <TextField className="custom-input"
                                defaultValue = {customer.address}
                                label="Address" 
                                InputProps={{ readOnly: true }}
                                variant="outlined" 
                                fullWidth
                            />

                            <TextField className="custom-input"
                                defaultValue = {customer.city}
                                label="City/Town" 
                                variant="outlined" 
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />  
                        </div>


                        <Button
                            onClick={()=>navigate("/dashboard/customers")}
                            variant="outlined" 
                            size="small"
                            startIcon={<ArrowBackIcon />}
                        >
                                Back
                        </Button>
                    </form>
                </div>




                <div 
                    className="grouped-fields-light"
                    style={{ 
                        flex: '0 0 37%', 
                        // backgroundColor: '#f5f5f5', 
                        padding: '1rem',
                        borderRadius: '8px',    
                        height: 'fit-content'
                }}>
                    <div style={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                        marginBottom: '1rem'
                    }}>
                        <Typography variant="h6">Phone Numbers</Typography>
                        <IconButton 
                            color="primary" 
                            onClick={() => setIsAddingNew(true)}
                            size="small"
                        >
                            <AddIcon />
                        </IconButton>
                    </div>

                    {/* Phone Numbers List */}
                    <List>
                        {isAddingNew && (
                            <ListItem sx={{ mb: 1 }}>
                                <NumberFormat 
                                    value={newNumber}
                                    // className="custom-input"
                                    fullWidth
                                    label="New Phone Number"
                                    variant="outlined" 
                                    customInput={TextField}
                                    format="### (###) ######" 
                                    mask="_"
                                    onChange={(e) => setNewNumber(e.target.value)}
                                    size="small"
                                />
                                {/* <IconButton color="primary" onClick={() => {
                                    if (newNumber) {
                                        setPhoneNumbers([...phoneNumbers, { number: newNumber }]);
                                        setIsAddingNew(false);
                                        setNewNumber('');
                                    }
                                }}> */}
                                <IconButton 
                                    color="primary" 
                                    onClick={handleSaveNew}
                                    disabled={addPhoneMutation.isLoading}
                >
                                    <SaveIcon />
                                </IconButton>
                                <IconButton onClick={() => setIsAddingNew(false)}>
                                    <CancelIcon />
                                </IconButton>
                            </ListItem>
                        )}

                        {customer.phone_numbers.map((phone, index) => (
                            <ListItem 
                                key={index}
                                sx={{ 
                                    mb: 1,
                                    backgroundColor: 'white',
                                    borderRadius: '4px',
                                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                                }}
                            >
                                <NumberFormat 
                                    // value={phone.number}
                                    value={editingIndex === index ? editingValue : phone.number}
                                    // className="custom-input"
                                    fullWidth
                                    variant="outlined" 
                                    customInput={TextField}
                                    format="### (###) ######" 
                                    mask="_"
                                    InputProps={{
                                        readOnly: editingIndex !== index,
                                    }}
                                    size="small"
                                    // onChange={(e) => {
                                    //     const updatedNumbers = [...phoneNumbers];
                                    //     updatedNumbers[index] = { number: e.target.value };
                                    //     setPhoneNumbers(updatedNumbers);
                                    // }}
                                    onChange={(e) => {
                                        if (editingIndex === index) {
                                            setEditingValue(e.target.value);
                                        }
                                    }}
                                />
                                {editingIndex === index ? (
                                    <>
                                        {/* <IconButton color="primary" onClick={() => setEditingIndex(null)}> */}
                                        <IconButton color="primary" onClick={() => handleSaveEdit(index)}>
                                            <SaveIcon />
                                        </IconButton>
                                        {/* <IconButton onClick={() => setEditingIndex(null)}> */}
                                        <IconButton onClick={() => {
                                            setEditingIndex(null);
                                            setEditingValue('');
                                        }}>
                                            <CancelIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    // <>
                                    //     <IconButton color="primary" onClick={() => setEditingIndex(index)}>
                                    //         <EditIcon />
                                    //     </IconButton>
                                    //     <IconButton 
                                    //         onClick={() => handleDelete(phone.id)}
                                    //         disabled={deletePhoneMutation.isLoading}
                                    //     >
                                    //         <DeleteIcon />
                                    //     </IconButton>
                                    // </>

                                    <>
                                    <IconButton 
                                        color="primary" 
                                        onClick={() => handleStartEdit(index, phone.number)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton 
                                        onClick={() => handleDelete(phone.id)}
                                        disabled={deletePhoneMutation.isLoading}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    </>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </div>




            </>

            }
        </section>
        </div>
    )

}

export default CustomerView;

