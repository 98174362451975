import { useState, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";


import authAxios from "../../components/authAxios";

import {useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { VisibilityOutlined } from "@mui/icons-material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArchiveIcon from '@mui/icons-material/Archive';

import { message } from "antd";

// import ThreeDotsMenu from "../../components/ThreeDotsMenu";
import EllipsisMenu from "../../components/EllipsisMenu";
import ContactNew from "../../components/ContactNew";
import CustomSearchBar from "../../components/CustomSearchBar";
import UserAlerts from "../../components/UserAlerts";

function Owners() {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const queryClient = useQueryClient();
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const [pagecount, setPageCount] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const tag = "OWNER"
    const [searchText, setSearchText] = useState("");

    const { data: owners } = useQuery({
        queryKey: ['owners', page, searchText],
        queryFn: async () => {
          const response = await authAxios.get(`/rentals/owners/?page=${page}&search=${searchText}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
        keepPreviousData : true,
    });

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const menuItems = (owner) => [
        {
            label: 'View',
            icon: <VisibilityOutlined />,
            onClick: () => {
                // Handle view action
                navigate(`/dashboard/owner/${owner.id}`)
            }
        },
        {
            label: 'Archive',
            icon: <ArchiveIcon />,
            onClick: () => {
                archiveMutation.mutate(owner)
            }
        },
        {
            label: 'Delete',
            icon: <DeleteOutlineIcon />,
            onClick: () => {
                deleteMutation.mutate(owner)
            }
        },
    ];

    const archiveMutation = useMutation({
        mutationFn: (owner) => {
            return authAxios.post(`/rentals/owners/${owner.id}/archive/`);
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                        // console.log("WE ARE HERE:", errorMessage) || setError(errorMessage)

                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            console.log("RESULT",result)
            message.success(`Successfully archived Owner record`);
            queryClient.invalidateQueries(['owners']);
            // navigate('/dashboard/leases/');
        },
    })

    const deleteMutation = useMutation({
        mutationFn: (owner) => {
            return authAxios.delete(`/rentals/owners/${owner.id}/`);
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                        // console.log("WE ARE HERE:", errorMessage) || setError(errorMessage)

                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            console.log("RESULT",result)
            message.success(`Successfully deleted Owner record`);
            queryClient.invalidateQueries(['owners']);
            // navigate('/dashboard/leases/');
        },
    })

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])


    return (
            <div className="dashboard-main">
                <section className="dashboard-content">
                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>
                    <div className="dashboard-content-header-nav">
                  

                        <DashboardHeader dashboardTitle="Owners" dashboardPreTitle="CRM" />

                        <div className="dashboard-content-header-nav-icons">
                            <CustomSearchBar 
                                searchText={searchText}
                                setSearchText={setSearchText}
                            />
                        </div>
                    </div>

                
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Name</th>
                                <th>Telephone</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th></th>
                            </tr>
                        </thead>

                        {owners && owners.map(owner => (

                            <tbody key={owner.id}>
                                <tr>
                                    <td>{owner.id}</td>
                                    <td>{owner.name}</td>
                                    <td>{owner.telephone}</td>
                                    <td>{owner.email}</td>
                                    <td>{owner.address}</td>
                                    <td className='styled-table-menu'>
                                        {/* <ThreeDotsMenu data={owner} threeSubMenu={threeSubMenu} /> */}
                                        <EllipsisMenu items={menuItems(owner)} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>

                    <ContactNew 
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        tag={tag}
                        // property={property.id}
                    />
                        
                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />


                    <Fab className="fab" color="primary" aria-label="add" onClick={()=>{
                        setIsModalOpen(true)}}
                        >
                        <AddIcon />
                    </Fab>
                </section>
            </div>

    )
}
export default Owners