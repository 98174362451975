
// import logo from '../assets/zidi-logo.svg'
// import foxgenLogo from '../assets/foxgen-logo.jpeg'
// import conceptSpacesLogo from '../assets/concept-spaces-logo.svg'
// import wadiLogo from '../assets/wadi-logo.svg'
// import absoluteCommerceLogo from '../assets/absolute-commerce-logo.svg'
// import rohimaxLogo from '../assets/rohimax-logo.svg'
// import evogressLogo from '../assets/evogress-logo.svg'
// import candqLogo from '../assets/candq-logo.svg'
// import patapayLogo from '../assets/patapay-logo.svg'

import mpesaLogo from '../../assets/mpesa-logo.svg'
import kcbLogo from '../../assets/kcb-logo.svg'
import coopBankLogo from '../../assets/coop-bank-logo.svg'
import equityBankLogo from '../../assets/equity-bank-logo.svg'



import paystackLogo from '../../assets/paystack-logo.svg'
import atLogo from '../../assets/at-logo.svg'
import awsLogo from '../../assets/aws-logo.svg'


const Partners = () =>{
    return(
        <section className="clients">
            <div className="clients-heading">
                Our Partners
            </div>
            <div className="clients-text">
                We deliver measurable impact for our customers through partnerships that understand property management challenges, opportunities and offer solutions that drive real results.
            </div>

            <div className='clients-logos-container'>
                {/* <img className='clients-logo' src={conceptSpacesLogo} alt="Concept Spaces Logo"/>  
                <img className='clients-logo' src={wadiLogo} alt="brand logo"/>  
                <img className='clients-logo' src={absoluteCommerceLogo} alt="brand logo"/>  
                <img className='clients-logo' src={foxgenLogo} alt="brand logo"/>  
                <img className='clients-logo' src={rohimaxLogo} alt="brand logo"/>  
                <img className='clients-logo' src={evogressLogo} alt="brand logo"/>  
                <img className='clients-logo' src={candqLogo} alt="brand logo"/>  
                <img className='clients-logo' src={patapayLogo} alt="brand logo"/>   */}
                <img className='clients-logo' src={mpesaLogo} alt="MPesa brand logo"/>  
                <img className='clients-logo' src={kcbLogo} alt="KCB brand logo"/>  
                <img className='clients-logo' src={coopBankLogo} alt="Co-op Bank brand logo"/>  
                <img className='clients-logo' src={equityBankLogo} alt="Equity Bank brand logo"/>  
                <img className='clients-logo' src={paystackLogo} alt="brand logo"/>  
                <img className='clients-logo' src={atLogo} alt="brand logo"/>  
                <img className='clients-logo' src={awsLogo} alt="brand logo"/>  

            </div>


        </section>
    )


}

export default Partners